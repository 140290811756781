import { Eye, EyeOff } from "lucide-react";
import { useId, useState } from "react";

import { useTranslation } from "react-i18next";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { cn } from "~/lib/utils";
import { useLanguage } from "~/utils/useLanguage";

export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export const useTranlateErrors = () => {
  const { t } = useTranslation();
  return {
    translateErrors: (errors: ListOfErrors) => {
      return errors
        ?.filter((e): e is string => typeof e === "string")
        .map((e) => {
          // We lose type safety here, but it's fine as we don't have a way to
          // express the type of the error message in the zod schema.
          // @ts-ignore
          return t(e) as string;
        });
    },
  };
};

export const ErrorList = ({
  id,
  errors,
}: {
  errors?: ListOfErrors;
  id?: string;
}) => {
  const errorsToRender = errors?.filter(Boolean);
  if (!errorsToRender?.length) return null;

  return (
    <ul id={id} className="flex flex-col gap-1">
      {errorsToRender.map((e) => (
        <li key={e} className="text-rose-500 text-sm">
          {e}
        </li>
      ))}
    </ul>
  );
};

export function Field({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  className?: string;
}) {
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;

  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <Input
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
      />
      <div className="pt-1">
        {errors?.length ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  );
}

export function PasswordField({
  labelProps,
  inputProps,
  eyeProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  eyeProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  errors?: ListOfErrors;
  className?: string;
}) {
  const [showPassword, setShowPassword] = useState(false);
  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;

  function toggleShowPassword() {
    setShowPassword((prev) => !prev);
  }

  const isRTL = useLanguage();

  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <Input
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
        type={showPassword ? "text" : "password"}
      />

      <div className="pt-1">
        {errors?.length ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>

      <Button
        type="button"
        className={cn(
          `absolute top-8 w-8 h-8 p-0 flex items-center align-middle`,
          isRTL ? "left-2" : "right-2",
        )}
        variant="ghost"
        size={"icon"}
        onClick={toggleShowPassword}
        {...eyeProps}
      >
        {showPassword ? (
          <Eye className="w-5 h-5 text-neutral-400" strokeWidth={1.5} />
        ) : (
          <EyeOff className="w-5 h-5 text-neutral-400" strokeWidth={1.5} />
        )}
      </Button>
    </div>
  );
}
